import React from 'react';
import OrderPage from 'rev.sdk.js/Templates/OrderPage';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as CustomCheckout from '../../Utils/CustomCheckout';

function OrderList(props) {
  return (
    <Wrapper>
      <OrderPage {...props} {...CustomCheckout.getExtraOrderPageProps(props)} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export default withPageEntry(OrderList);
